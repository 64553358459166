import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

const routes = [
  {
    path: "/",
    exact: true,
    Component: lazy(() => import("./Screens/Home/index")),
  },
  {
    path: "/profile/:slug",
    exact: true,
    Component: lazy(() => import("./Screens/Profile/index")),
  },
  {
    path: "/service/:serviceId/:ClinicId",
    exact: true,
    Component: lazy(() => import("./Screens/Appointment/index")),
  },
  {
    path: "/service_doctodopay/:serviceId/:ClinicId",
    exact: true,
    Component: lazy(() => import("./Screens/Appointment/cashfree")),
  },
  {
    path: "/clinic/:ClinicId",
    exact: true,
    Component: lazy(() => import("./Screens/ClinicProfile/index")),
  },
  {
    path: "/feedback-thank-you",
    exact: true,
    Component: lazy(() => import("./Screens/ThankYouFeedback/index")),
  },
  {
    path: "/404",
    exact: true,
    Component: lazy(() => import("./Screens/Error404/index")),
  },
  {
    path: "/paid-sucessfully",
    exact: true,
    Component: lazy(() => import("./Screens/AlreadyPaid/index")),
  },
  {
    path: "/appointment/:appointment_id",
    exact: true,
    Component: lazy(() => import("./Screens/AppointmentPayment/index")),
  },
  {
    path: "/appointment_doctodopay/:appointment_id",
    exact: true,
    Component: lazy(() => import("./Screens/AppointmentPayment/cashfree")),
  },
  {
    path: "/video-consultation/:appointmentId/:email",
    exact: true,
    Component: lazy(() => import("./Screens/LiveVideo/index")),
  },
  {
    path: "/video-call/:appointment_id/",
    exact: true,
    Component: lazy(() => import("./Screens/VideoConsultation/index")),
  },
  {
    path: "/feedback/:clinic_master/:appointment_id",
    exact: true,
    Component: lazy(() => import("./Screens/Feedback/index")),
  },
  {
    path: "/thank-you",
    exact: true,
    Component: lazy(() => import("./Screens/ThankYou/index")),
  },
  {
    path: "/cashfree",
    exact: true,
    Component: lazy(() => import("./Screens/CashFree/index")),
  },
];

class App extends React.Component {
  render() {
    return (
      <Fragment>
        <Helmet titleTemplate="Doctodo | %s" title="Title" />
        {/* <Route
          exact
          path="/"
          render={() => {
            window.location.replace("https://doctodo.in/");
            return null;
          }}
        /> */}
        {routes.map(({ path, Component, exact }) => (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={() => {
              return (
                <Suspense fallback={null}>
                  <Component />
                </Suspense>
              );
            }}
          />
        ))}
      </Fragment>
    );
  }
}

export default withRouter(App);
