import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Router from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App/Assets/css/general.css";
import "./App/Assets/css/feeback.css";
import "./App/Assets/css/style.scss";
import Axios from "axios";
import { createBrowserHistory } from "history";
Axios.defaults.baseURL = "https://apidoctodo.trixno.com/";
Axios.defaults.headers.common.Authorization = ``;
Axios.defaults.headers.post["Content-Type"] = "application/json";
const history = createBrowserHistory();
ReactDOM.render(
  <BrowserRouter>
    <Router history={history} />
  </BrowserRouter>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { history };
